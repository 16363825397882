.disabled-row {
	color: rgb(144, 143, 143);
}

.disabled-row .ant-tag {
	background-color: #777 !important;
}

.ant-table .archive {
	color: #9b9b9b;
	text-decoration: line-through;
}

.ant-dropdown-trigger {
	width: 100%;
}